import React from "react";
import TooltipComponent from "../../toolTip";
import mediaBreakPoint from "~utils/media-breakpoint";
import "../inputs.less";

export const InputWrapper = ({ inputType, extraQuestions, ...props }: any) => {
  const tabBreakpoint = mediaBreakPoint("(max-width: 900px)");
  const {
    formik,
    wClassName,
    pClassName,
    className,
    question,
    name,
    hideQuestion,
    mainDivStyle,
    labelText,
    toolTip,
    tooltipText,
    tabTextStyle,
    spacing,
    fontFamily,
    visibility,
    display,
    isChild,
    note
  } = props;
  const error = _.get(formik.errors, name);
  const touched = _.get(formik.touched, name);

  return (
    <div
      className={`${ error && touched ? "error-div-active" : "" } div-wrapping-component ${wClassName} ${isChild ? "child-overlay-ui" : ""}`}
      name={name}
      style={{
        ...mainDivStyle,
        display: display
          ? display
          : tabBreakpoint && visibility == "hidden"
          ? "none"
          : "",
        visibility: visibility || "",
      }}
    >
      {!hideQuestion && question && (
        <p
          className={`${pClassName ? pClassName : 'tab-text'} ${fontFamily ? "tab-text-light" : "tab-text"}`}
          style={{ ...tabTextStyle }}
        >
          {question}
          {toolTip && (
            <TooltipComponent
              className="tool-tip-component"
              tooltipText={tooltipText}
            />
          )}
        </p>
      )}

      <p style={{marginBottom:'5px'}}>{extraQuestions || null}</p>

      {props.children}

      {note && <span>{note}</span>}

      {error && touched && (
        <div className="formik-errors" style={{ color: "red" }}>
          {error}
        </div>
      )}
    </div>
  );
};
